import React, { useEffect, useState } from "react"
import { DataGrid, GridToolbar } from "@material-ui/data-grid"
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles, Card, CardContent, Button, Dialog, DialogContent, DialogTitle, Paper, CardActions } from "@material-ui/core";
import { themeGreen, whiteColor, roseColor } from "assets/jss/material-dashboard-pro-react.js";
import TransactionDetails from "./TransactionDetails";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import merchantApiService from "./APIService";
import { merchantTransactionApiService } from "./APIService";
import HWMessage from "HWLibrary/HWMessage";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import TransactionDescription from "views/Transaction/SpecificTransaction";
import { merchantPointsApiService } from "./APIService";
import { Link, useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";


export default function MerchantTransactions (props) {

    var merchantarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    merchantarray= [...obj["Merchants"]];

    HWLocalStorage.save (1, "previousMerchantDetailsTab");

    const [showDetails, setShowDetails] = React.useState(false)
    const [aTransactionDetails, setTransactionDetails] = React.useState()

    const useStyle = makeStyles(themes => ({

        root: {
            // '& .MuiDataGrid-colCell': { color: roseColor[0] },
            // '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
            // '& .Mui-checked': { borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)' },
            // '& .MuiButton-textPrimary' : {color: roseColor[0]},
            // '& .MuiDataGrid-colCellTitle': {  color : 'primary', fontFamily : 'roboto', fontWeight : 200 }
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
            '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
            '& .MuiDataGrid-rowCount' : { fontWeight : '100', value : (loading) ? 0 : rowCount },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
    }));

    const classes = useStyle();

    const getTextColor = action => (action === 'plus' ? '#21781C' : '#D83653');
    const columns = [
        { field: 'date', headerName: 'Date', minWidth: 100,flex:1.0,sortable:false, renderCell : cellParams => { 
            var d = new Date(cellParams.row.transactionTime); 
            return (
            <Link>
                <Paper style = {{ backgroundColor : '#138DE8' }}> 
                    <GridContainer > 
                        <GridItem xs = {12} style = {{ marginBottom : '-32px', color : 'floralwhite' }} > <center> <b> { d.toLocaleDateString('default', { day : 'numeric' }) } </b>  </center>  </GridItem> 
                        <GridItem xs = {12} style = {{ color : 'floralwhite' }} > <center> <b>   { d.toLocaleDateString('default', { month : 'long' }) } </b> </center>  </GridItem> 
                    </GridContainer> 
                </Paper>
            </Link>); 
            }}, 
        { field: 'description', headerName: 'Description', sortable:false,minWidth: 600,flex:4, renderCell : cellParams => {
            return <Link style = {{ color : '#138DE8' }} >  {   cellParams.row.transactionType === "PointPurchasePending" ? "Point Purchase Transaction Failed"  : cellParams.row.description }  </Link>
        } },
        { field: 'amount', headerName: 'Amount',sortable:false, minWidth: 200,flex:1.5, renderCell : cellParams => {
            return cellParams.row.pointAction && <span style = {{ color : cellParams.row.transactionType === "PointPurchasePending" ? "red" :getTextColor (cellParams.row.pointAction) }} > <b> { cellParams.row.transactionType === "PointPurchasePending"? "" : cellParams.row.pointAction === 'plus' ? '+' : '-' } { cellParams.row.pointsRewarded || cellParams.row.pointsReturned || cellParams.row.pointsPurchased || cellParams.row.pointsDeducted || cellParams.row.pointsSpent || cellParams.row.yamahaPointsRewarded} </b> </span>
        } }
    ]


    if (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member ) {
        var currentMerchant = props.location.params.state.member;
        HWLocalStorage.save (JSON.stringify(currentMerchant), "merchantStore");
    }
    if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id) {
        var currentMerchantId = props.location.params.state.id;
        HWLocalStorage.save (JSON.stringify(currentMerchantId), "merchantProps");
    }
    const [ merchant, setMerchant ] = useState (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member || JSON.parse(HWLocalStorage.load ("merchantStore")));
    const [ merchantId, setMerchantId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id || JSON.parse(HWLocalStorage.load("merchantProps")));
    

    const [ sortModel, setSortModel ] = useState (null);
    const [ filterModel, setFilterModel ] = useState (null);
    const [ pageSize, setPageSize ] = useState (100);
    const [ page, setPage ] = useState (0);
    const [ refreshState, setRefreshState ] = useState(0);
    const [ loading, setLoading ] = useState (false);
    const [ list, setList ] = useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
    const [ rows, setRows ] = useState ([]);
    const [ txnOpen, setTxnOpen ] = useState ();
    const [ openTransaction, setOpenTransaction ] = useState ();
    const [ totalPoints, setTotalPoints ] = useState();

    
    useEffect (() => {
        if (merchant && merchant.id) {
            var paginationBookmark = 0;
            var fetchLimit = 0;
            var merchantId = merchant.id;

            setLoading (true);
            merchantPointsApiService.fetchMerchantPoints ({ paginationBookmark, fetchLimit, merchantId }) 
            .then (response => {
                if (! response || ! response.data) {
                    HWMessage.show ("Error while fetching points","error");
                    return console.log("Error while fetching points");
                }
                HWMessage.show("Points details fetched successfully","success");
                setTotalPoints(response.data.totalPoints);
             })
            .catch (error => { 
                HWMessage.show ("Error while fetching points","error");
                return console.log("Error while fetching points", error);
             })
            .finally (() => { setLoading(false); });
        }
    }, [ merchantId, refreshState ]);

    useEffect (() => {
        if (merchant && merchant.id) {
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;
            var merchantId = merchant.id;

            setLoading (true);
            merchantTransactionApiService.fetchMerchantTransactions ({ paginationBookmark, fetchLimit, sortModel, filterModel, merchantId })
            .then (response => {  
                if (! response || ! response.data) {  
                    HWMessage.show ("Transactions cannot be fetched.","error");
                    return console.log("Error while fetching response");                
                }
                HWMessage.show ("Transactions fetched successfully","success");
                setRowCount (response.data.countTransactions);
                setRows (response.data.transactionList);
            })
            .catch (error => {  
                HWMessage.show ("Error while fetching transactions");
                return console.log("Error while fetching transactions ", error);
            })
            .finally (() => { setLoading (false); });
        }
    }, [ refreshState, page, pageSize, sortModel, filterModel, merchantId ]);
    
    let history = useHistory ();
    const accruePoints = () => {
        if (merchant) {
            var groupIds = [ ]; groupIds.push (merchant);
            var currentRequest = { requestCategory : 'Merchant', requestAudience : 'Group', requestType : 'Accrue', userCount : 1, groupIds : groupIds };
            history.push({ pathname : "reward-points", state : { currentRequest , createMode : true, groupIds : groupIds  }}); 
        }
    };
    const deductPoints = () => {
        if (merchant) {
            var groupIds = [ ]; groupIds.push (merchant);
            var currentRequest = { requestCategory : 'Merchant', requestAudience : 'Group', requestType : 'Deduct', userCount : 1, groupIds : groupIds };
            history.push({ pathname : "reward-points", state : { currentRequest , createMode : true, groupIds : groupIds }}); 
        }   
    };

    return (
        <>
            <BlockUi blocking = { loading } message = {'Loading Transactions'} >
            <Card>
                <CardContent>
                    <GridContainer>

                        <GridItem>
                           <center> <h3 style = {{ color : '#138DE8' }} > Points Balance : { totalPoints } </h3> </center> 
                        </GridItem>
                        <GridItem xs = {12} >  
                            <DataGrid 
                            autoHeight
                            showToolbar 
                            fullWidth 
                            columns={ columns } 
                            rowHeight = { 65 }
                            rows = { rows }
                            page = { page }
                            rowCount = { rowCount }
                            pageSize = { pageSize }
                            className = { classes.root }
                            
                            rowsPerPageOptions ={ [25, 50, 100 ]}
                            loading = { loading }

                            paginationMode = 'server'
                            onPageChange = { (gridChangeParams) => {setPage (gridChangeParams)}  }
                            onPageSizeChange = { (gridChangeParams) => { setPage(0); setPageSize(gridChangeParams); } }

                            sortingMode = 'server'
                            onSortModelChange = { params => { if (params.length > 0) { setSortModel(params[0]); } } }

                            filterMode = 'server'
                            onFilterModelChange = { params => { if (params.items.length > 0) { setFilterModel(params.items[0]); } } }
                            onRowClick = { rowDetails => { console.log("Rowing", rowDetails); setOpenTransaction (rowDetails.row.id) ; setTxnOpen(true);   } }
                        />
                        { txnOpen && openTransaction && <TransactionDescription open = { txnOpen } setOpen = { setTxnOpen } transactionId = { openTransaction } merchantId = { merchant.id } creator = {'merchant'} />} 
                        </GridItem>
                    </GridContainer>
                    
                </CardContent>
              { merchantarray.includes("Action") &&  <CardActions style = {{ justifyContent : 'center' }} color = "primary" > 
                        <Button variant = "contained" raised style = {{ color : 'floralwhite', background : '#138DE8' , textTransform:'None' }} onClick = { accruePoints }  > Accure Points </Button>
                        <Button variant = "contained" raised style = {{ color : 'floralwhite', background : '#FF9017' , textTransform: 'None' }} onClick = { deductPoints } > Deduct Points </Button>
                </CardActions> }
                
            </Card>
            </BlockUi>
        </>
    )
};